import { render, staticRenderFns } from "./Step2.vue?vue&type=template&id=44763e61&scoped=true&"
import script from "./Step2.vue?vue&type=script&lang=js&"
export * from "./Step2.vue?vue&type=script&lang=js&"
import style1 from "./Step2.vue?vue&type=style&index=1&id=44763e61&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44763e61",
  null
  
)

export default component.exports
import {QTable,QTr,QTh,QTd} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTr,QTh,QTd})
