//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TdCheckbox, TdDivider } from 'td-ui'

export default {
  name: 'Step2',
  components: {
    TdCheckbox,
    TdDivider,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    importType: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    nothingFound: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      tableColumns: [
        {
          name: 'index',
          label: '№',
          field: 'index',
          align: 'left',
        },
        { name: 'given_name', label: 'Имя', field: 'given_name', align: 'left' },
        { name: 'phone', label: 'Телефон', field: 'phone', align: 'left' },
        { name: 'role', label: 'Должность', field: 'role', align: 'left' },
        { name: 'section', label: 'Отдел', field: 'section', align: 'left' },
        { name: 'selected', label: '', field: 'selected' },
      ],
      tableData: [],
      selectedContacts: [],
      selectAll: true,
    }
  },
  watch: {
    selectedContacts: {
      handler () {
        this.$emit('contact-select', this.selectedContacts)
        if (this.selectedContacts.length === this.tableData.length) {
          this.selectAll = true
        } else this.selectAll = false
      },
      immidiate: true,
    },
    selectAll: {
      handler () {
        if (this.selectAll) {
          this.selectedContacts = [...this.tableData]
        }
      },
      immidiate: true,
    },
  },
  created () {
    this.tableData = JSON.parse(JSON.stringify(this.value))
    this.tableData.map((item, index) => {
      item.index = index + 1
      item.selected = true
      item.imported = false
      this.selectedContacts.push(item)
    })
  },
  methods: {
    handleChange (importVal, itemIndex) {
      const newItems = [...this.value]
      newItems[itemIndex].import = importVal
      this.$emit('input', newItems)
    },
    handleSelectAll (isSelectAll) {
      if (!isSelectAll) this.selectedContacts = []
    },
  },
}
